import React, { useState, useEffect, useContext } from 'react'
import { useMediaPredicate } from 'react-media-hook'
import HoverContext from '../context/HoverContext'

// Styles
import { List, ListWrap, FImage, PaginationWrap } from './style'

// Components
import Link from '../components/ui/Link'
import Paragraph from '../components/ui/Paragraph/index'
import Heading from '../components/ui/Heading/index'

const PostList = React.memo(({ posts, archive }) => {
	const mobileScreen = useMediaPredicate('(max-width: 525px)')
	const { setCursorStyle } = useContext(HoverContext)
	const reversePostOrder = posts.reverse()
	const [blog, setBlog] = useState({
		totalRecords: posts.length,
		pageLimit: !mobileScreen ? 7 : 4,
		data: [],
		currentPages: [],
		currentPage: 1,
		pageNeighbours: 0,
	})

	const [loaded, setLoaded] = useState(false)
	const { currentPage, pageNeighbours, totalRecords, pageLimit, data } = blog
	const totalPages = Math.ceil(totalRecords / pageLimit)

	useEffect(() => {
		if (!loaded) {
			setLoaded(true)
			setBlog({
				...blog,
				data: reversePostOrder,
				currentPages: reversePostOrder.slice(0, 0 + pageLimit),
				pageNeighbours: Math.max(0, Math.min(blog.pageNeighbours, 2)),
			})
		}
	}, [blog, reversePostOrder, pageLimit, loaded, posts])

	// Buttons for paginations
	const LEFT_PAGE = 'LEFT'
	const RIGHT_PAGE = 'RIGHT'

	/**
	 * Helper method for creating a range of numbers
	 * range(1, 5) => [1, 2, 3, 4, 5]
	 */

	const range = (from, to, step = 1) => {
		let i = from
		const range = []

		while (i <= to) {
			range.push(i)
			i += step
		}
		return range
	}

	/**
	 * Let's say we have 10 pages and we set pageNeighbours to 2
	 * Given that the current page is 6
	 * The pagination control will look like the following:
	 *
	 * (1) < {4 5} [6] {7 8} > (10)
	 *
	 * (x) => terminal pages: first and last page(always visible)
	 * [x] => represents current page
	 * {...x} => represents page neighbours
	 */

	const fetchPageNumbers = () => {
		const totalNumbers = pageNeighbours * 2 + 3
		const totalBlocks = totalNumbers + 2

		if (totalPages > totalBlocks) {
			const startPage = Math.max(2, currentPage - pageNeighbours)
			const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours)
			let pages = range(startPage, endPage)
			/**
			 * hasLeftSpill: has hidden pages to the left
			 * hasRightSpill: has hidden pages to the right
			 * spillOffset: number of hidden pages either to the left or to the right
			 */
			const hasLeftSpill = startPage > 2
			const hasRightSpill = totalPages - endPage > 1
			const spillOffset = totalNumbers - (pages.length + 1)

			switch (true) {
				// handle: (1) < {5 6} [7] {8 9} (10)
				case hasLeftSpill && !hasRightSpill: {
					const extraPages = range(startPage - spillOffset, startPage - 1)
					pages = [LEFT_PAGE, ...extraPages, ...pages]
					break
				}

				// handle: (1) {2 3} [4] {5 6} > (10)
				case !hasLeftSpill && hasRightSpill: {
					const extraPages = range(endPage + 1, endPage + spillOffset)
					pages = [...pages, ...extraPages, RIGHT_PAGE]
					break
				}

				// handle: (1) < {4 5} [6] {7 8} > (10)
				case hasLeftSpill && hasRightSpill:
				default: {
					pages = [LEFT_PAGE, ...pages, RIGHT_PAGE]
					break
				}
			}

			return [1, ...pages, totalPages]
		}

		return range(1, totalPages)
	}

	const pages = fetchPageNumbers()

	const gotoPage = (page) => {
		const currentPage = Math.max(0, Math.min(page, totalPages))
		const offset = (currentPage - 1) * pageLimit
		const currentPages = data.reverse().slice(offset, offset + pageLimit)
		setBlog({ ...blog, currentPage, currentPages })
	}

	// Pagination Handlers
	const handleClick = (page) => (event) => {
		event.preventDefault()
		window.scrollTo({ top: 0, behavior: 'smooth' })
		gotoPage(page)
	}

	const handleMoveLeft = (event) => {
		event.preventDefault()
		gotoPage(currentPage - pageNeighbours * 2 - 1)
	}

	const handleMoveRight = (event) => {
		event.preventDefault()
		gotoPage(currentPage + pageNeighbours * 2 + 1)
	}

	const pagination = (
		<React.Fragment>
			<PaginationWrap aria-label="Blog Pagination">
				<ul className="pagination">
					{pages.length > 1 &&
						pages.map((page, index) => {
							if (page === LEFT_PAGE)
								return (
									<li key={index}>
										<button
											type="button"
											className="page-item left"
											href="#"
											aria-label="Previous"
											onClick={handleMoveLeft}
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="32"
												height="32"
												viewBox="0 0 32 32"
											>
												<g
													id="Last_Page"
													data-name="Last Page"
													transform="translate(-1709 -1868)"
												>
													<rect
														id="Rectangle_60"
														data-name="Rectangle 60"
														width="32"
														height="32"
														transform="translate(1709 1868)"
														fill="#ac6400"
														opacity="0"
													/>
													<path
														id="Path_17"
														data-name="Path 17"
														d="M5730.127,1876.159l5,5-5,5"
														transform="translate(-4005.127 2.842)"
														fill="none"
														stroke="#2a2a2a"
														strokeWidth="1.5"
													/>
													<path
														id="Path_18"
														data-name="Path 18"
														d="M5730.127,1876.159l5,5-5,5"
														transform="translate(-4009.127 2.842)"
														fill="none"
														stroke="#2a2a2a"
														strokeWidth="1.5"
													/>
												</g>
											</svg>
										</button>
									</li>
								)

							if (page === RIGHT_PAGE)
								return (
									<li key={index}>
										<button
											type="button"
											className="page-item"
											aria-label="Next"
											onClick={handleMoveRight}
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="32"
												height="32"
												viewBox="0 0 32 32"
											>
												<g
													id="Last_Page"
													data-name="Last Page"
													transform="translate(-1709 -1868)"
												>
													<rect
														id="Rectangle_60"
														data-name="Rectangle 60"
														width="32"
														height="32"
														transform="translate(1709 1868)"
														fill="#ac6400"
														opacity="0"
													/>
													<path
														id="Path_17"
														data-name="Path 17"
														d="M5730.127,1876.159l5,5-5,5"
														transform="translate(-4005.127 2.842)"
														fill="none"
														stroke="#2a2a2a"
														strokeWidth="1.5"
													/>
													<path
														id="Path_18"
														data-name="Path 18"
														d="M5730.127,1876.159l5,5-5,5"
														transform="translate(-4009.127 2.842)"
														fill="none"
														stroke="#2a2a2a"
														strokeWidth="1.5"
													/>
												</g>
											</svg>
										</button>
									</li>
								)

							return (
								<li
									key={index}
									className={`page-item${
										currentPage === page ? ' active' : ''
									}`}
								>
									<button
										type="button"
										className="page-link"
										href="#"
										onClick={handleClick(page)}
									>
										{page}
									</button>
								</li>
							)
						})}
				</ul>
			</PaginationWrap>
		</React.Fragment>
	)
	return (
		<List>
			{blog &&
				blog.currentPages.map((post, index) => {
					var monthNames = [
						'Jan',
						'Feb',
						'March',
						'April',
						'May',
						'June',
						'July',
						'Aug',
						'Sep',
						'Oct',
						'Nov',
						'Dec',
					]
					let date = new Date(post.PostCreated)

					// const slug = slugify(post.Title.replace(/-/g, ' '), {
					// 	lower: true,
					// 	remove: /[?*+~.()'"!:@]/g,
					// })

					return (
						<ListWrap key={index} className="blog-wrap">
							{post.FeaturedImage && (
								<Link
									className="img-wrap"
									to={'/post/' + post.Seo.Url}
									onMouseEnter={() => {
										setCursorStyle({ height: 0, width: 0, transition: '.1.2s' })
									}}
									onMouseLeave={() => {
										setCursorStyle({
											height: false,
											width: false,
											transition: false,
										})
									}}
								>
									<FImage
										loading="lazy"
										src={post.FeaturedImage.imageFile.publicURL}
										alt={post.FeaturedImage.alternativeText}
										title={post.FeaturedImage.caption}
									/>
								</Link>
							)}
							<div className="text-wrapp">
								<div className="top-info">
									<Paragraph>
										{monthNames[date.getMonth()]} {date.getDate()}
									</Paragraph>
									<span
										style={{
											mixBlendMode: 'difference',
											backgroundColor: 'white',
										}}
									></span>
									<Paragraph>{post.ReadTime}</Paragraph>
								</div>
								<Link
									className="title-link"
									to={'/post/' + post.Seo.Url}
									onMouseEnter={() => {
										setCursorStyle({ height: 0, width: 0, transition: '.1.2s' })
									}}
									onMouseLeave={() => {
										setCursorStyle({
											height: false,
											width: false,
											transition: false,
										})
									}}
								>
									<Heading level={2}>
										{(() => {
											if (index !== 0) {
												return post.Title.length > 65
													? post.Title.substring(0, 65) + ' ...'
													: post.Title
											} else {
												return post.Title.length > 90
													? post.Title.substring(0, 90) + ' ...'
													: post.Title
											}
										})()}
									</Heading>
								</Link>
								{(() => {
									const counter = post.Field.length - 1 ? 136 : 72
									if (post.Field.length > 0) {
										if (post.Field[0].Layout === 'text') {
											return (
												<Paragraph>
													{post.Field[0].Text.length > counter
														? post.Field[0].Text.substring(0, counter) + ' ...'
														: post.Field[0].Text}
												</Paragraph>
											)
										} else {
											return (
												<React.Fragment>
													<Paragraph>
														{post.Field[0].Text.length > counter
															? post.Field[0].Text.substring(0, counter) +
															  ' ...'
															: post.Field[0].Text}
													</Paragraph>
												</React.Fragment>
											)
										}
									}
								})()}
								<Link
									className="link"
									to={'/post/' + post.Seo.Url}
									onMouseEnter={() => {
										setCursorStyle({ height: 0, width: 0, transition: '.1.2s' })
									}}
									onMouseLeave={() => {
										setCursorStyle({
											height: false,
											width: false,
											transition: false,
										})
									}}
								>
									Read more
								</Link>
							</div>
						</ListWrap>
					)
				})}
			{pagination}
		</List>
	)
})

export default PostList
