import styled from 'styled-components'
import React from 'react'

export const BlogContainer = styled.div`
	max-width: 140.8em;
	margin: 0 auto;
	padding: 20em 0;

	@media (max-width: 1450px) {
		padding-left: 5em;
		padding-right: 5em;
	}

	@media (pointer: coarse), (max-width: 1024px) {
		padding: 15em 5em 5em 5em;
	}

	@media (max-width: 768px) {
		padding: 15em 5em 5em 5em;
	}

	@media (max-width: 700px) {
		padding: 14em 2em 5em 2em;
	}

	@media (max-width: 525px) {
		padding: 10em 2em 5em 2em;
	}
`

export const TitleColumn = styled.div`
	max-width: 930px;
	margin-bottom: 12.8em;

	@media (pointer: coarse), (max-width: 1024px) {
		margin-bottom: 2.4em;
	}
`

export const Header = styled.div`

	&.title {
		font-size: 2.625rem;
		line-height: 3.75rem;
	}

	@media (max-width: 1024px) {
		&.title {
			font-size: 2rem;
			line-height: 2.75rem;
		}
	}

	@media (max-width: 525px) {
		&.title {
			max-width: 322px;
			font-size: 30px;
			line-height: 44px;
		}
	}
`

/******************************************
 * Gatsby bug (file must export component)
 *****************************************/
const Style = () => {
	return <div>style</div>
}

export default Style
