import React from 'react'
import styled from 'styled-components'

const Heading = React.memo((props) => {
	const level = props.level ? props.level : 1

	return (
		<Row {...props} level={level}>
			<Wrapper children={props.children} as={`h${level}`} />
		</Row>
	)
})

const Row = styled.div.attrs({
	className: 'content-item title',
})`
	overflow: hidden;
	position: relative;
	mix-blend-mode: difference;
	z-index: 9;
	font: ${({ level }) => 4.0625 / level}rem / ${({ level }) => 5.625 / level}rem
		'Mont Heavy';

	@media (max-width: 525px) {
		font: ${({ level }) => 2.1875 / level}rem / ${({ level }) => 3 / level}rem
			'Mont Heavy';
		max-width: 310px;
	}

	&.title {
		font-size: 2.625rem;
		line-height: 3.75rem;
	}

	@media (max-width: 1024px) {
		&.title {
			font-size: 2rem;
			line-height: 2.75rem;
		}
	}

	@media (max-width: 525px) {
		&.title {
			max-width: 322px;
			font-size: 30px;
			line-height: 44px;
		}
	}
`

const Wrapper = styled.div`
	font: inherit;
	color: white;
	margin-top: 0;
	margin-bottom: 0;
`

export default Heading
