import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Styles
import { Header, BlogContainer, TitleColumn } from './style'

// Ui components
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import PostList from '../../templates/postList'
import Heading from '../../components/ui/Heading'

const Blog = React.memo((props) => {
	const data = useStaticQuery(graphql`
		query Blog {
			timacum {
				page(id: "5fbbcb4667844431c9e4bd88") {
					Title
					Seo {
						TitleTag
						MetaDescription
						Url
					}
				}
				posts {
					id
					createdAt
					FeaturedImage {
						id
						url
						alternativeText
						caption
						imageFile {
							publicURL
							childImageSharp {
								fluid(maxWidth: 1920, quality: 100) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
					Title
					Seo {
						TitleTag
						MetaDescription
						Url
					}
					PostCreated
					ReadTime
					Field {
						... on TIMACUM_ComponentContentText {
							id
							Text
							Layout
						}
						... on TIMACUM_ComponentContentTitle {
							id
							Layout
							Title
						}
						... on TIMACUM_ComponentContentImage {
							id
							Layout
							Image {
								url
								alternativeText
								caption
								imageFile {
									childImageSharp {
										fluid(maxWidth: 1920, quality: 100) {
											...GatsbyImageSharpFluid
										}
									}
								}
							}
						}
						... on TIMACUM_ComponentContentVideo {
							id
							Layout
							Link
						}
						... on TIMACUM_ComponentContentTwoImages {
							id
							DesktopMobilePreview
							DoubleDropShadow
							Layout
							FirstImage {
								url
								alternativeText
								caption
								imageFile {
									publicURL
									childImageSharp {
										fluid(maxWidth: 1920, quality: 100) {
											...GatsbyImageSharpFluid
										}
									}
								}
							}
							SecondImage {
								url
								alternativeText
								caption
								imageFile {
									publicURL
									childImageSharp {
										fluid(maxWidth: 1920, quality: 100) {
											...GatsbyImageSharpFluid
										}
									}
								}
							}
						}
						... on TIMACUM_ComponentContentTitleText {
							id
							Title
							Layout
							Text
						}
						... on TIMACUM_ComponentContentArticleCta {
							id
							Layout
							CTA
							Link
							Subtitle
							Title
							Image {
								url
								alternativeText
								caption
								imageFile {
									publicURL
									childImageSharp {
										fluid(maxWidth: 1920, quality: 100) {
											...GatsbyImageSharpFluid
										}
									}
								}
							}
						}
					}
				}
			}
		}
	`)

	const { Seo } = data.timacum.page
	const { posts } = data.timacum
	const [sortedPosts, setSorted] = useState(null)

	useEffect(() => {
		// Sort post by PostCreated attrribute
		const sPosts = posts.slice().sort((a, b) => {
			var dateA = new Date(a.PostCreated)
			var dateB = new Date(b.PostCreated)
			return dateA - dateB
		})
		setSorted(sPosts)
	}, [posts])

	return (
		<Layout location={props.location}>
			<SEO data={Seo} />
			<BlogContainer>
				<TitleColumn>
					<Header as={Heading} level={1}>
						Timacum Blog - Bringing you closer to Blockchain technology
					</Header>
				</TitleColumn>
				{sortedPosts && <PostList posts={sortedPosts} />}
			</BlogContainer>
		</Layout>
	)
})

export default Blog
